import React from "react";
import clsx from "clsx";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Section from "../section";

export default function FAQAccordionSection({
  className,
  faqs = [
    {
      question: "How many users per month does each plan include?",
      answer:
        "The 14-day free trial includes 50 users/month, the Essentials 100 users/month, the Business 1000 users/month, and the Enterprise 5000 users/month.  ",
    },
  ],
}) {
  return (
    <Section className={clsx(className, "bg-gray-50")}>
      <div className="max-w-2xl mx-auto space-y-2">
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Frequently asked questions
        </h2>
      </div>
      <div className="mt-8">
        <dl className="space-y-6 divide-y divide-borderLight">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                      <span className="font-semibold text-xl leading-normal tracking-normal  text-gray-900">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        <ChevronDownIcon
                          className={clsx(
                            open ? "-rotate-180" : "rotate-0",
                            "h-6 w-6 transform"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base md:text-lg text-gray-500 font-light">
                      {faq.answer}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </Section>
  );
}
