import { CheckIcon } from "@heroicons/react/20/solid";
import { graphql } from "gatsby";
import React, { useMemo, useState } from "react";
import Layout from "../components/layout";
import PricingPlan from "../components/pricing-plan";
import Section from "../components/section";
import CTADemo from "../components/sections/cta-demo";
import FAQAccordionSection from "../components/sections/faq-accordion";
import SEO from "../components/seo";
import { Button, LinkButton } from "../components/button";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

const faqs = [
  {
    question: "What is conversational AI?",
    answer:
      "Conversational artificial intelligence (AI) refers to technologies, like Chatbots or Virtual Agents users can talk to. They use large volumes of data, machine learning and natural language processing to help imitate human-like interactions, recognizing speech and text inputs and translating their meanings across various languages.",
  },
  {
    question: "How do chatbot solutions improve customer satisfaction?",
    answer:
      "Today, chatbots can consistently manage customer interactions 24x7 while continuously improving the quality of the responses and keeping costs down. Chatbots automate workflows and free up employees from repetitive tasks. A chatbot can also eliminate long wait times for phone-based customer support, or even longer wait times for email, chat and web-based support, because they are available immediately to any number of users at once. That’s a great user experience—and satisfied customers are more likely to exhibit brand loyalty.",
  },
  {
    question:
      "Do virtual agents or chatbots respond to customers in real-time?",
    answer:
      "A chatbot can answer questions 24 hours a day, seven days a week. It can provide a new first line of support, supplement support during peak periods, or offer an additional support option. At the very least, using a chatbot can help reduce the number of users who need to speak with a human, which can help businesses avoid scaling up staff due to increased demand or implementing a 24-hour support staff.",
  },
  {
    question:
      "Can I deploy my AI bot to social media channels like Facebook Messenger, Whatsapp, or Slack?",
    answer:
      "Yes, you can deploy your chatbot to Facebook Messenger, Slack, Teams, SMS with Twilio, and WhatsApp. Many others are possible, please contact our Customer Success Team to explore those options.",
  },
  {
    question:
      "Does chatbot technology use machine learning or natural language processing (NLP)?",
    answer:
      "Coeus-Watson is built on deep learning, machine learning and natural language processing (NLP) models to elevate customer experiences and help customers change an appointment, track a shipment, or check a balance. Watson also uses machine learning algorithms and asks follow-up questions to better understand customers and pass them off to a human agent when needed",
  },
  {
    question:
      "How many unique chat users (MAUs) per month does each plan include?",
    answer:
      "The Free plan includes 25 unique users/month, Essentials plan includes 50 unique users/month, Business plan 100 users/month, and the Enterprise plan is custom.",
  },
  {
    question:
      "What if I go over the allotted unique users, in a month, that my plan include?",
    answer:
      "No Problem. If you go over the allotted unique chat users your chat will continue to operate, so your customers always have access to your Ai Assistant. You will automatically be charged for extra unique chat users, based on your plan.",
  },
  {
    question: "What does MAU stand for?",
    answer:
      "MAU stands for Monthly Active Users. Also referred to as unique chat users",
  },
  {
    question: "What is an unique chat user?",
    answer:
      "A unique chat user or Monthly Active User (MAU), is any unique end-user who has one or more meaningful interactions with your assistant per calendar month across any integrations on a service instance (provided each user is tracked with the same identifier).",
  },
  {
    question: "Do I need to host my own cloud services? ",
    answer:
      "You do not, we try to make it as easy as possible for you. Coeus makes launching your Ai Assistant very easy. For Coeus Self Service, it can usually be available within hours. With Coeus Insta-Launch for Enterprise, you can configure and launch the AI Assistant with FAQ contents within days.",
  },
  {
    question: "How much does the Soul Machines integration cost?",
    answer:
      "The cost for integration and ongoing operation of Soul Machines varies depending on your specific configuration.  Please contact the Customer Success Team for help with cost estimates.",
  },
  {
    question: "Am I contractually obligated if I sign up for a certain level?",
    answer:
      "You may move packages at any time, and your new package will be prorated; however, you can only move to a lower level during contract renewal (monthly or annual). You may cancel at any time without penalty and service will end at the end of the current billing cycle unless you specify to end immediately.",
  },
];

const PricingPage = ({ data }) => {
  const plans = data.allBillingPlan.nodes;
  const freePlan = useMemo(
    () => plans.find((plan) => plan.planId === "free"),
    [plans]
  );
  const essentialsPlan = useMemo(
    () => plans.find((plan) => plan.planId === "essentials"),
    [plans]
  );
  const businessPlan = useMemo(
    () => plans.find((plan) => plan.planId === "business"),
    [plans]
  );
  const [monthlyPlan, setMonthlyPlan] = useState(false);
  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 4,
  });

  function handleClick() {
    setMonthlyPlan(!monthlyPlan);
  }

  return (
    <Layout>
      <Section className="bg-gray-50">
        <div className="space-y-4">
          <div className="space-y-2">
            <h1 className="text-4xl sm:text-5xl md:text-6xl text-center font-bold">
              Pricing
            </h1>
            <p className="text-lg text-center font-normal">
              Find the best package to fit your current needs!
            </p>
          </div>
          <div className="py-6 min-h-[84px]">
            <div className="grid grid-cols-3 max-w-[300px] mx-auto">
              <p className="ml-3 text-base font-medium text-gray-900">
                Pay monthly
              </p>
              <div className="relative flex items-center justify-center">
                <label class="relative cursor-pointer">
                  <input
                    type="checkbox"
                    checked={!monthlyPlan}
                    className="sr-only peer"
                    onClick={handleClick}
                  />
                  <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="relative inline-flex items-start text-left">
                <p className="text-base font-medium text-gray-900">
                  Pay yearly
                </p>
                <p className="absolute top-5 text-green-600 text-sm font-semibold w-max">
                  Save up to 30%
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-4 gap-8">
            <PricingPlan
              name="Free"
              monthlyPrice={0}
              PricingExtra={
                <p className="text-gray-500 max-w-[145px] mt-2 text-center mx-auto text-sm">
                  25 free MAUs included then{" "}
                  <b>
                    {formatter.format(
                      freePlan.mauBatchCostAmount / freePlan.mauBatchCount / 100
                    )}
                  </b>{" "}
                  per MAU
                </p>
              }
              featureList={[
                { label: "25 included MAUs", isInfo: true },
                { label: "Up to 50 FAQs", isInfo: true },
                { label: "1 included admin user", isInfo: true },
                { label: "Website chat widget" },
                { label: "Ratings and feedback" },
                { label: "Analytics" },
                { label: "Company branding" },
              ]}
              Button={
                <LinkButton
                  href={`https://app.coeus.chat/signup?plan=free&interval=${
                    monthlyPlan ? "monthly" : "yearly"
                  }`}
                  className="font-semibold"
                >
                  Get started <ArrowRightIcon height={18} className="ml-1" />
                </LinkButton>
              }
            />
            <PricingPlan
              name="Essentials"
              monthlyPrice={
                !monthlyPlan
                  ? essentialsPlan.yearlyAmount / 12
                  : essentialsPlan.monthlyAmount
              }
              mauPrice={
                essentialsPlan.mauBatchCostAmount / essentialsPlan.mauBatchCount
              }
              mauMinimumSignificantDigits={3}
              pricingText={
                !monthlyPlan
                  ? `Billed ${formatter.format(
                      essentialsPlan.yearlyAmount / 100
                    )} annually`
                  : undefined
              }
              featureList={[
                { label: "25% off MAUs", isInfo: true },
                { label: "50 included MAUs", isInfo: true },
                { label: "Up to 100 FAQs", isInfo: true },
                { label: "2 included admin users", isInfo: true },
                { label: "Everything in Free" },
                { label: "Business messenger add-ons" },
              ]}
              Button={
                <LinkButton
                  href={`https://app.coeus.chat/signup?plan=essentials&interval=${
                    monthlyPlan ? "monthly" : "yearly"
                  }`}
                  className="font-semibold"
                >
                  Get started <ArrowRightIcon height={18} className="ml-1" />
                </LinkButton>
              }
            />
            <PricingPlan
              name="Business"
              highlighted
              monthlyPrice={
                !monthlyPlan
                  ? businessPlan.yearlyAmount / 12
                  : businessPlan.monthlyAmount
              }
              mauPrice={
                businessPlan.mauBatchCostAmount / businessPlan.mauBatchCount
              }
              mauMaximumSignificantDigits={1}
              mauMinimumSignificantDigits={1}
              pricingText={
                !monthlyPlan
                  ? `Billed ${formatter.format(
                      businessPlan.yearlyAmount / 100
                    )} annually`
                  : undefined
              }
              featureList={[
                { label: "50% off MAUs", isInfo: true },
                { label: "100 included MAUs", isInfo: true },
                { label: "Up to 200 FAQs", isInfo: true },
                { label: "3 included admin users", isInfo: true },
                { label: "Everything in Essentials" },
                { label: "Pre-built content add-ons" },
              ]}
              Button={
                <LinkButton
                  href={`https://app.coeus.chat/signup?plan=business&interval=${
                    monthlyPlan ? "monthly" : "yearly"
                  }`}
                  className="font-semibold"
                >
                  Get started <ArrowRightIcon height={18} className="ml-1" />
                </LinkButton>
              }
            />
            <PricingPlan
              name="Enterprise"
              isEnterprise
              monthlyPrice={
                !monthlyPlan
                  ? businessPlan.yearlyAmount / 12
                  : businessPlan.monthlyAmount
              }
              mauPrice={
                businessPlan.mauBatchCostAmount / businessPlan.mauBatchCount
              }
              pricingText=""
              featureList={[
                { label: "Custom MAUs included", isInfo: true },
                { label: "Custom FAQs", isInfo: true },
                { label: "Custom admin users", isInfo: true },
                { label: "Everything in Business" },
                { label: "Multi-lingual capabilities" },
                { label: "Custom forms" },
                { label: "Custom integrations" },
              ]}
              Button={
                <Button
                  className="font-semibold"
                  onClick={() => window.coeus.intent("Show me a contact form")}
                >
                  Contact us
                </Button>
              }
            />
          </div>
          <div className="text-sm text-slate-600 font-light">
            <ul className="space-y-1">
              <li>
                A Monthly Active User (MAU) is any unique end-user who has one
                or more meaningful interactions with your assistant per calendar
                month across any integrations on a service instance (provided
                each user is tracked with the same identifier).
              </li>
              <li>
                Monthly cost could vary based on MAU usage. MAUs are billed in
                groups of <strong>25</strong>. You are only charged for MAUs if
                you exceed your plan's included amount.
              </li>
            </ul>
          </div>
        </div>
      </Section>
      <Section>
        <h1 className="text-4xl md:text-5xl text-center font-bold">
          Compare Plans
        </h1>
        <p className="text-lg text-center font-normal mt-2">
          Compare the features of each plan and find the one that suites you
          best.
        </p>
      </Section>
      <div className="w-full max-w-7xl mx-auto mt-0 overflow-x-scroll sm:overflow-x-hidden">
        <div className="w-full px-8 xl:px-0 min-w-[720px]">
          <table className="table-fixed w-full">
            <thead>
              <tr>
                <th></th>
                <th align="center" className="px-4 py-2.5">
                  <div className="flex flex-col">
                    <span>Free</span>
                  </div>
                </th>
                <th align="center" className="px-4 py-2.5">
                  <div className="flex flex-col">
                    <span>Essentials</span>
                  </div>
                </th>
                <th align="center" className="px-4 py-2.5">
                  <div className="flex flex-col">
                    <span>Business</span>
                  </div>
                </th>
                <th align="center" className="px-4 py-2.5">
                  <div className="flex flex-col">
                    <span>Enterprise</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y border-borderLight border-b border-t">
              <tr className="bg-gray-50">
                <td className="text-left font-bold px-4 py-2.5">Chat</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="font-light">
                <td className="text-left px-4 py-2.5">Price per MAU</td>
                <td align="center">$2</td>
                <td align="center">$1.50</td>
                <td align="center">$1</td>
                <td align="center">Custom</td>
              </tr>
              <tr className="font-light">
                <td className="text-left px-4 py-2.5">Included MAUs</td>
                <td align="center">25</td>
                <td align="center">50</td>
                <td align="center">100</td>
                <td align="center">Custom</td>
              </tr>
              <tr className="font-light">
                <td className="text-left px-4 py-2.5">Admin users</td>
                <td align="center">1</td>
                <td align="center">2</td>
                <td align="center">3</td>
                <td align="center">Custom</td>
              </tr>
              {/* <tr className="font-light">
                <td className="text-left px-4 py-2.5">
                  MAU Overage cost (per 25)
                </td>
                <td align="center">$50</td>
                <td align="center">$37.50</td>
                <td align="center">$25</td>
                <td align="center">Custom</td>
              </tr> */}
              {/* <tr className="font-light">
                <td className="text-left px-4 py-2.5">Transcript history</td>
                <td align="center">No</td>
                <td align="center">Yes</td>
                <td align="center">Yes</td>
                <td align="center">Custom</td>
              </tr> */}
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Customizable UI
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              {/* <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Contact form
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr> */}
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Analytics
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Ratings and feedback
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Company branding
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Coeus Web Chat channel
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Slack channel add-on*
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Teams channel add-on*
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  WhatsApp channel add-on*
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Custom forms add-on*
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="bg-gray-50">
                <td className="text-left font-bold px-4 py-2.5">
                  Knowledge Base
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {/* <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Auto FAQs
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr> */}
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  FAQs
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  50
                </td>
                <td align="center" className="px-4 py-2.5">
                  100
                </td>
                <td align="center" className="px-4 py-2.5">
                  200
                </td>
                <td align="center" className="px-4 py-2.5">
                  Custom
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Document discovery capability
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="bg-gray-50">
                <td className="text-left font-bold px-4 py-2.5">
                  Watson Assistant
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Pre-built skills add-on*
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Multi-lingual add-on*
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Custom API integration add-on*
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="bg-gray-50">
                <td className="text-left font-bold px-4 py-2.5">
                  Infrastructure
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Dedicated Watson Assistant
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <MinusIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <MinusIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Dedicated Watson Discovery
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <MinusIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <MinusIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <div className="relative w-auto h-auto">
                                        <CheckIcon className="h-5 w-5 text-green-500" />
                                        <span className="text-gray-600 absolute top-0 right-1/2 translate-x-5">*</span>
                                    </div> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="bg-gray-50">
                <td className="text-left font-bold px-4 py-2.5">Support</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  24/7 email support
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Customer success team
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Support ticketing
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Co-creation option**
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <CheckIcon className="h-5 w-5 text-green-500" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
              <tr className="font-light">
                <td align="center" className="text-left px-4 py-2.5">
                  Design thinking option
                </td>
                <td align="center" className="px-4 py-2.5 mx-auto">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  {/* <XMarkIcon className="h-5 w-5 text-gray-400" /> */}
                </td>
                <td align="center" className="px-4 py-2.5">
                  <CheckIcon className="h-5 w-5 text-green-500" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="text-sm text-slate-600 font-light mt-4 max-w-7xl mx-auto px-8 xl:px-0">
        <ul className="space-y-1">
          <li>
            * An "add-on" is a paid feature and is not included in the
            monthly/annual price. Add-on's may require additional setup and
            development.
          </li>
          <li>
            ** Co-creation is an optional add-on where we will offer support in
            the design and creation of your projects.
          </li>
        </ul>
      </div>
      <FAQAccordionSection className="mt-8" faqs={faqs} />

      <CTADemo />
    </Layout>
  );
};

export default PricingPage;

export const pageQuery = graphql`
  query PlansQuery {
    allBillingPlan {
      nodes {
        currency
        id
        planId
        instanceType
        limits {
          activeChatUsersPerMonth
          adminUsersPerOrg
          domainsPerProject
          examplesPerFaq
          faqsPerProject
          projectsPerOrg
          languagesPerProject
          usersPerOrg
        }
        mauBatchCostAmount
        mauBatchCount
        monthlyAmount
        name
        yearlyAmount
      }
    }
  }
`;

export const Head = () => {
  return (
    <SEO
      title="Pricing"
      description="Browse and compare the features of each plan and find the one that better suites your needs!"
    ></SEO>
  );
};
