import React from "react";
import clsx from "clsx";

export default function Section({ id, className, children }) {
    return (
        <section id={id} className={clsx(className, "py-[72px] w-full overflow-x-hidden")}>
            <div className="container max-w-7xl mx-auto px-8 xl:px-0">
                {children}
            </div>
        </section>
    );
}
