import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { LinkButton } from "./button";

export default function PricingPlan({
  name,
  clarificationText,
  savingsText,
  additionalText,
  monthlyPrice,
  mauPrice,
  pricingText,
  PricingExtra,
  Button,
  mauMaximumSignificantDigits = 3,
  mauMinimumSignificantDigits = 2,
  planMaximumSignificantDigits = 3,
  planMinimumSignificantDigits = 1,
  actionOnClick = undefined,
  actionText = "Try it now",
  featureListText,
  featureList = ["Up to 25 chat users / month"],
  isEnterprise = false,
  highlighted = false,
}) {
  const planPriceFormatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: planMaximumSignificantDigits,
    minimumSignificantDigits: planMinimumSignificantDigits,
  });
  const mauPriceFormatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: mauMaximumSignificantDigits,
    minimumSignificantDigits: mauMinimumSignificantDigits,
  });

  return (
    <div
      className={clsx(
        "relative p-8 text-left space-y-6 w-full shadow-sm border border-gray-200 bg-white flex flex-col rounded-2xl h-full"
      )}
    >
      <div className="flex flex-col space-y-6">
        <div className="space-x-1 text-center">
          <span className="font-semibold text-2xl">{name}</span>
          {clarificationText && (
            <span className="text-gray-600 text-sm">{clarificationText}</span>
          )}
        </div>
        <div className="flex flex-col items-center justify-center min-h-[92px]">
          {!isEnterprise ? (
            <div className="flex flex-col">
              <span className="font-semibold text-4xl text-center">
                <div className="inline-flex items-baseline space-x-1.5">
                  <span>{planPriceFormatter.format(monthlyPrice / 100)}</span>
                  <span className="text-xl font-extralight text-slate-600">
                    /
                  </span>
                  <span className="text-xl font-extralight text-slate-600">
                    month
                  </span>
                </div>
              </span>
              {mauPrice && (
                <span className="font-semibold text-lg text-center">
                  <div className="inline-flex items-baseline space-x-1.5">
                    <span>{mauPriceFormatter.format(mauPrice / 100)}</span>
                    <span className="text-lg font-extralight text-slate-600">
                      /
                    </span>
                    <span className="text-base font-extralight text-slate-600">
                      MAU
                    </span>
                  </div>
                </span>
              )}
              {pricingText && (
                <span className="mt-1 text-sm text-slate-500 text-center">
                  {pricingText}
                </span>
              )}
              {PricingExtra && PricingExtra}
            </div>
          ) : (
            <div className="flex h-full items-start">
              <span className="text-3xl font-semibold mt-3">
                Ask for a quote
              </span>
            </div>
          )}
        </div>
        {savingsText && (
          <div className="text-sm text-black mt-2">
            <p>
              <span>*</span> {savingsText}
            </p>
          </div>
        )}

        {Button}
        {/* {actionOnClick && <Button onClick={actionOnClick}>{actionText}</Button>} */}
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex flex-col justify-between h-full space-y-6">
        <div>
          <span className="text-base font-semibold">{featureListText}</span>
          <div className="space-y-2 mt-2">
            {featureList.map((feature) => (
              <div
                key={feature}
                className="relative flex items-center space-x-2.5"
              >
                {feature.isInfo ? (
                  <InformationCircleIcon className="h-5 w-5 text-blue-500" />
                ) : (
                  <CheckIcon className="h-5 w-5 text-green-500" />
                )}
                <span className="text-base font-normal truncate">
                  {feature.label}
                </span>
              </div>
            ))}
          </div>
        </div>
        {additionalText && (
          <div className="text-sm text-black mt-2">
            <p>
              <span>*</span> {additionalText}
            </p>
          </div>
        )}
      </div>
      {highlighted && (
        <div className="absolute min-w-max -top-[41px] left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-600 text-green-600 px-3 py-1 rounded-md">
          Best price for value
        </div>
      )}
    </div>
  );
}
