import { useSiteMetadata } from "../hooks/use-site-metadata";
import React from "react";

function SEO({ description, title, keywords, pathname, children }) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    facebookUrl,
    twitterUrl,
    linkedinUrl
  } = useSiteMetadata();

  const seo = {
    title: `${title || defaultTitle}`,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
  };

  const documentTitle = `${seo.title} | Coeus`

  return (
    <>
      <title>{documentTitle}</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content="chat,customer,communication,email,support,assistant"></meta>
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:site_name" content="Coeus" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${siteUrl}/opengraph.png`} />
      <meta property="og:image:width" content="1090" />
      <meta property="og:image:height" content="561" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@coeuschat"></meta>
      <meta name="twitter:image" content={`${siteUrl}/opengraph.png`} />
      <link rel="sitemap" type="application/xml" title="Sitemap" href={`${siteUrl}/sitemap.xml`}></link>
      <link rel="apple-touch-icon" sizes="57x57" href={`${siteUrl}/apple-touch-icon-57x57.png`} />
      <link rel="apple-touch-icon" sizes="60x60" href={`${siteUrl}/apple-touch-icon-60x60.png`} />
      <link rel="apple-touch-icon" sizes="72x72" href={`${siteUrl}/apple-touch-icon-72x72.png`} />
      <link rel="apple-touch-icon" sizes="76x76" href={`${siteUrl}/apple-touch-icon-76x76.png`} />
      <link rel="apple-touch-icon" sizes="114x114" href={`${siteUrl}/apple-touch-icon-114x114.png`} />
      <link rel="apple-touch-icon" sizes="120x120" href={`${siteUrl}/apple-touch-icon-120x120.png`} />
      <link rel="apple-touch-icon" sizes="144x144" href={`${siteUrl}/apple-touch-icon-144x144.png`} />
      <link rel="apple-touch-icon" sizes="152x152" href={`${siteUrl}/apple-touch-icon-152x152.png`} />
      <link rel="shortcut icon" type="image/x-icon" href={`${siteUrl}/favicon.ico`} />
      <link rel="icon" type="image/png" href={`${siteUrl}/favicon-196x196.png`} sizes="196x196" />
      <link rel="icon" type="image/png" href={`${siteUrl}/favicon-96x96.png`} sizes="96x96" />
      <link rel="icon" type="image/png" href={`${siteUrl}/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${siteUrl}/favicon-16x16.png`} sizes="16x16" />
      <link rel="icon" type="image/png" href={`${siteUrl}/favicon-128.png`} sizes="128x128" />
      <link rel="icon" type="image/png" href={`${siteUrl}/favicon.png`} />
      <meta name="msapplication-TileColor" content="#DF1D23" />
      <meta name="msapplication-TileImage" content={`${siteUrl}/mstile-144x144.png`} />
      <meta name="msapplication-square70x70logo" content={`${siteUrl}/mstile-70x70.png`} />
      <meta name="msapplication-square150x150logo" content={`${siteUrl}/mstile-150x150.png`} />
      <meta name="msapplication-square310x310logo" content={`${siteUrl}/mstile-310x310.png`} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://datafusionspecialists.com",
            "name": "Data Fusion Specialists",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "888-933-7832",
              "contactType": "Customer Support"
            },
            "sameAs": [
              "https://www.facebook.com/DataFusionSpecialists",
              "https://twitter.com/DatafusionUSA",
              "https://www.linkedin.com/company/data-fusion-specialists/"
            ]
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "Coeus",
            "alternateName": "Coeus Chat",
            "url": "${siteUrl}/",
            "logo": "${siteUrl}/favicon.png",
            "sameAs" : [
              "${facebookUrl}",
              "${twitterUrl}",
              "${linkedinUrl}"
            ]
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context" : "http://schema.org",
            "@type": "WebApplication",
            "applicationCategory": "Messaging",
            "name": "Coeus",
            "description": "${defaultDescription}",
            "url": "${siteUrl}/",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "softwareVersion": "1.0.0",
            "operatingSystem": "All"
          }
        `}
      </script>
      {children}
    </>
  );
}

export default SEO;
