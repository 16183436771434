import React from "react";
import { Link } from "gatsby";

export default function CTADemo({
  title = "Ready to Get Started?",
  // subtitle = "Contact us to get access to a free demo of Coeus!"
}) {
  return (
    <div
      id="CTADemo"
      className="text-white bg-gradient-to-r from-red-500 to-red-900 py-[86px]"
    >
      <div className="space-y-3 px-6">
        <h1 className="text-4xl md:text-5xl font-bold text-center">{title}</h1>
        {/* <p className="text-lg md:text-xl text-center">{subtitle}</p> */}
      </div>
      <div className="flex flex-col px-10 sm:px-0 sm:flex-row justify-center mt-8 space-y-5 sm:space-y-0 sm:space-x-5">
        <Link
          to="/pricing"
          className="inline-flex font-openSans items-center justify-center rounded-md font-bold transition-all bg-white hover:bg-gray-100 px-7 py-4 text-gray-900"
        >
          Get started
        </Link>
      </div>
    </div>
  );
}
